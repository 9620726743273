/* requires:
polyfill.js
cart.js
*/

// we're receiving the products object as a string so we need to parse it
const Products = JSON.parse('products_replaced_during_build');

// the product IDs order is the order they will appear in the bulk order form
// const bulkProductIds = ['a4a740e9-b6e8-4aca-827c-cdd99a0cb01d', '87c1641b-ba03-4ec2-96d9-3ec059594d75'];
const bulkProductIds = [Products.michiganDI];

const bulkOrder = {
  bulkBox: document.querySelector('#bulk-box'),
  bulkTable: document.querySelector('#bulk-table'),
  expandedDescription: [],

  init: async (callAPIs = true) => {
    if (!callAPIs) {
      const localCart = cart.getLocalCart();
      const bulkProducts = localCart?.cartProducts.map((item) => item.productId);
      const combinedProducts = bulkProductIds.concat(bulkProducts);
      bulkOrder.renderProducts(combinedProducts);

      // using early return to skip the code below if the IF statement is satisfied
      return;
    }

    const productAPIUrl = 'product_api_url_replaced_during_build';
    const unpublishedData = 'product_unpublished_replaced_during_build';
    const defaultHost = 'default_host_replaced_during_build';
    const defaultGroupId = 'default_group_replaced_during_build';
    const queryTestPrice = pricing.getQueryString('tp') || pricing.readCookie('_up4tp') || null;
    const queryGroupId =
      pricing.getQueryString('gid') ||
      pricing.getQueryString('lgr') || // checking for LGR because some LGRs of NYSC are the group IDs
      pricing.readCookie('_up4gid') ||
      defaultGroupId ||
      null;
    const queryCoupon = pricing.getQueryString('cc') || pricing.readCookie('_up4cc') || null;

    const testPriceQuery = queryTestPrice ? `&testPrice=${queryTestPrice}` : '';
    const groupIdQuery = queryGroupId ? `&groupId=${queryGroupId}` : '';
    const cookieCoupon = pricing.readCookie('_up4cc');
    const couponQuery = queryCoupon ? `&couponcode=${queryCoupon}` : cookieCoupon ? `&couponcode=${cookieCoupon}` : '';
    const unpublishedQuery = `&unpublished=${unpublishedData}`;

    // API url
    const productAPI = `${productAPIUrl}/GetProductsBy/?host=${defaultHost}${testPriceQuery}${groupIdQuery}${couponQuery}${unpublishedQuery}&fullSiteBuild=true`;

    const timestamp = new Date().getTime();
    const cacheBust = `&timestamp=${timestamp}`;
    const cartId = cart.getCartId();
    const cartAPI = `${cart.apiBaseUrl}/Carts/${cartId}/?completeProducts=true&unpublished=${cart.unpublishedData}${cacheBust}`;

    const productsData = fetch(productAPI).then((res) => {
      if (res.status !== 200 && res.status < 500) {
        console.log('API Status Code: ' + res.status);
        return res.json();
      }
      if (res.status >= 500) {
        console.log('API Status Code: ' + res.status);
        return;
      }
      return res.json();
    });
    const cartData = cartId
      ? fetch(cartAPI).then((res) => {
          if (res.status !== 200 && res.status < 500) {
            console.log('API Status Code: ' + res.status);
            return res.json();
          }
          if (res.status >= 500) {
            console.log('API Status Code: ' + res.status);
            return;
          }
          return res.json();
        })
      : null;

    // fetching both the full product data and the cart
    Promise.all([productsData, cartData])
      .then(([products, cart]) => {
        if (!products?.errors) {
          // store/update the productsData with the complete product info
          bulkOrder.storeProductData(products);

          // storing cart data from API in case it was updated in the cart domain
          if (cart && cart?.cartProducts?.length) {
            sessionStorage.setItem('localCart', JSON.stringify(cart));
          }

          const bulkProducts = cart && cart?.cartProducts?.length ? cart?.cartProducts.map((item) => item.productId) : [];
          const combinedProducts = bulkProductIds.concat(bulkProducts);

          bulkOrder.renderProducts(combinedProducts);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },

  storeProductData: function (products) {
    const productsArr = [];
    for (let product of products) {
      const productObj = {
        id: product.id,
        name: product.name,
        description: product.description,
        productCategory: {
          subCategoryName: product.productCategory?.subCategoryName || null,
          categoryName: product.productCategory?.categoryName || null,
          verticalName: product.productCategory?.verticalName || null,
        },
        tags: product.tags ? [...product.tags] : [],
        pricing: {
          price: product.pricing?.price,
          discountAmount: product.pricing?.discountAmount,
          priceAfterDiscount:
            product.pricing?.discountAmount > 0
              ? Math.round((product.pricing.price - product.pricing.discountAmount) * 100) / 100
              : product.pricing?.price,
          discountSource: product.pricing?.discountSource,
          typePricing: product.pricing?.typePricing,
          agencyFee: product.pricing?.agencyFee,
          agencyFeeName: product.pricing?.agencyFeeName,
          groupId: product.pricing?.groupId,
          coupon: product.pricing.discountSource === 'Coupon' ? pricing.readCookie('_up4cc') : null, // manually added
        },
        productBulkTiers: product.productBulkTiers,
      };
      productsArr.push(productObj);
    }
    sessionStorage.setItem('productsData', JSON.stringify(productsArr));
  },

  formatAmount: (number) => {
    return Number(Math.round(number + 'e2') + 'e-2');
  },

  renderProducts: (bulkProductIds) => {
    // render products in the table
    const productsData = sessionStorage.getItem('productsData') && JSON.parse(sessionStorage.getItem('productsData'));
    if (!productsData || productsData.length < 1) return;

    const localCart = cart.getLocalCart();

    const rowTemplate = (product) => {
      if (!product) return '';
      const cartProduct = localCart?.cartProducts.find((item) => item.productId === product.id);

      const pricing = product?.pricing;
      // const unitPrice =
      //   cartProduct?.discountSource === 'Bulk'
      //     ? bulkOrder.formatAmount(cartProduct?.unitPriceWithDiscountAndFeeIfHidden).toFixed(2)
      //     : bulkOrder.formatAmount(pricing?.discountAmount).toFixed(2)
      //     ? bulkOrder.formatAmount(pricing?.price - pricing?.discountAmount).toFixed(2)
      //     : bulkOrder.formatAmount(pricing?.price).toFixed(2);
      // const unitPrice = pricing?.discountAmount
      //   ? (pricing?.price - pricing?.discountAmount).toFixed(2)
      //   : pricing?.price?.toFixed(2);
      const unitPrice = cartProduct?.discountSource
        ? bulkOrder.formatAmount(cartProduct?.unitPriceWithDiscountAndFeeIfHidden).toFixed(2)
        : pricing?.discountAmount
        ? bulkOrder.formatAmount(pricing?.price - pricing?.discountAmount).toFixed(2)
        : bulkOrder.formatAmount(pricing?.price).toFixed(2);
      const priceDisplay = '$' + unitPrice.toString();
      const agencyFee = product.pricing.agencyFee ? bulkOrder.formatAmount(product.pricing.agencyFee).toFixed(2) : 0;
      const quantity = cartProduct ? cartProduct.quantity : '';
      // const discountAmount =
      //   cartProduct?.discountSource === 'Bulk' ? bulkOrder.formatAmount(cartProduct.unitDiscountAmount).toFixed(2) : '0.00';
      const discountAmount = cartProduct?.discountSource
        ? bulkOrder.formatAmount(cartProduct.unitDiscountAmount).toFixed(2)
        : '0.00';

      const bulkRates = JSON.stringify(product.productBulkTiers) || '[]';

      // Place code below for adding fees
      // ${product.pricing.agencyFee ? `<br><span class="small text-muted">Regulatory Fee: $${agencyFee}</span>` : ''}

      return `
        <tr class="d-flex flex-wrap d-sm-table-row">
          <td class="product-name-td align-middle">
            <span class="product-name d-block">${product.name}${
              product.tags.includes('spanish') ? ' (Curso en español)' : ''
            }</span>
            <span class="bulk-description d-block ${
              bulkOrder.expandedDescription.includes(product.id) ? 'is-open' : ''
            }" data-pid="${product.id}">
              <span class="description-toggle small text-muted">View description</span>
              <span class="description-content d-block">${product.description}</span>
            </span>
          </td>
          <td class="flex-fill align-middle text-center" data-label="Quantity">
            <label for="${product.id}" class="visually-hidden">Quantity</label>
            <input
              class="form-control qty text-center"
              id="${product.id}"
              name="${product.id}"
              placeholder="0"
              type="number"
              pattern="[0-9]"
              data-price="${unitPrice}"
              data-fee="${agencyFee}"
              value="${quantity}"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              aria-label="Quantity"
            />
          </td>
          <td class="flex-fill align-middle text-center text-sm-end" data-label="Discount">
            ${
              product.productBulkTiers && product.productBulkTiers.length
                ? `<span
              class="bulk-rates-trigger"
              data-product-name="${product.name}"
              data-bulk-rates='${bulkRates}'
              tabindex="0"
              role="button"
            >
              $${discountAmount}<span class="bulk-rates-icon">?</span>
            </span>`
                : `<span class="me-3 pe-1">$${discountAmount}</span>`
            }
          </td>
          <td class="flex-fill align-middle text-center text-sm-end" data-label="Price"><strong>${priceDisplay}</strong></td>
        </tr>
      `;
    };

    let productDisplay = '';
    const renderedProductIds = [];
    for (let productId of bulkProductIds) {
      const product = productsData.find((product) => product.id === productId);
      if (!renderedProductIds.includes(productId)) {
        // if product doesn't exist in the below array, add the product
        productDisplay += rowTemplate(product);
        renderedProductIds.push(productId); // adds each productId to array
      }
    }

    const bulkTable = bulkOrder.bulkTable;
    if (bulkTable) {
      bulkOrder.bulkBox.classList.remove('processing');
      const bulkTableBody = bulkTable.querySelector('tbody');
      bulkTableBody.innerHTML = productDisplay;

      bulkOrder.initQtyInputEvent(bulkTable);
      bulkOrder.initDiscountTiers(bulkTable);

      // calculate total amount, this is using data from the product API and bulk discount data from the cart
      const totalAmount = localCart.cartProducts.reduce((acc, cur) => {
        const itemSubTotal =
          (!cur.hiddenFee ? cur.unitPriceWithDiscountAndFeeIfHidden : cur.unitPriceWithDiscountAndFee) * cur.quantity;

        return bulkOrder.formatAmount(acc + itemSubTotal);
      }, 0);

      bulkOrder.initCartTotalAndBtn(totalAmount);
    }
  },

  initQtyInputEvent: (bulkTable) => {
    const inputCollection = [...bulkTable.querySelectorAll('input.qty')];

    // had to move the event listener into the input input instead of the document to prevent readding the event listener
    // when the bulk form is re-rendered
    for (let input of inputCollection) {
      input.addEventListener(
        'change',
        (event) => {
          const eventElement = event.target;
          const qtyInput = eventElement.closest('input.qty');
          if (qtyInput) {
            // console.log(qtyInput.value);
            const localCart = cart.getLocalCart();
            const productId = qtyInput.getAttribute('name');
            const quantity = qtyInput.value;
            const isInCart = localCart?.cartProducts.some((product) => product.productId === productId);

            bulkOrder.bulkBox.classList.add('processing');

            // calling any cart method will reinitialize the bulk order form with new data
            if (quantity > 0) {
              // if product doesn't exist in cart, add it to the cart
              if (!isInCart) {
                console.log('Adding product to cart!');
                cart.addToCart({ productId, quantity }, false, false, () => bulkOrder.init(false));
              }
              // if product exist in cart, update the cart
              else {
                // call update cart method
                console.log('Updating cart!');
                cart.updateCart({ productId, quantity }, () => bulkOrder.init(false));
              }
            } else {
              // remove product from cart when qty is 0
              if (isInCart) {
                console.log('Deleting product from cart!');
                cart.removeFromCart(productId, () => bulkOrder.init(false));
              } else {
                bulkOrder.bulkBox.classList.remove('processing');
              }
            }
          }
        },
        true
      );
    }
  },

  initDiscountTiers: (bulkTable) => {
    const modalTriggers = [...bulkTable.querySelectorAll('.bulk-rates-trigger')];
    const bulkRateModal = document.querySelector('#bulk-rate-modal');
    const modalTitle = bulkRateModal.querySelector('.product-name');
    const bulkRateInfoDisplay = bulkRateModal.querySelector('.bulk-rate-info');

    // if there is a coupon in the cart display the discount message
    const localCart = cart.getLocalCart();
    const isCouponApplied = localCart?.cartProducts?.some((product) => product?.discountSource === 'Coupon');

    // comment and uncomment one of the boostrap modal
    // for bootstrap v4+
    // const bulkModal = $('#bulk-rate-modal');
    // for boostrap v5+
    const bulkModal = new bootstrap.Modal('#bulk-rate-modal');

    const showModal = (event) => {
      const eventElement = event.target;
      const triggerEl = eventElement.closest('.bulk-rates-trigger');
      if (triggerEl) {
        if (event.key === ' ') {
          event.preventDefault();
        }
        // update modal information
        // compute each bulk tier and compose sentence like below
        // [{"minQuantity":10,"unitDiscount":2},{"minQuantity":20,"unitDiscount":5}]
        // Buy 10-19 and get a unit price of $248.00<br>
        // Buy 20 or more and get a unit price of $245.00

        modalTitle.innerHTML = triggerEl.dataset.productName;
        const discountMsgEl = bulkRateModal.querySelector('.modal-discounts-msg');

        const bulkRates = JSON.parse(triggerEl.dataset.bulkRates);

        if (bulkRates && bulkRates.length > 0) {
          let bulkRateInfo = '';
          for (let i = 0; i < bulkRates.length; i++) {
            const bulkPrice = bulkOrder.formatAmount(bulkRates[i].discountTierUnitPrice).toFixed(2);
            if (i < bulkRates.length - 1) {
              const tier = `${bulkRates[i].minQuantity}-${bulkRates[i + 1].minQuantity - 1}`;
              bulkRateInfo += `Buy ${tier} and get a unit price of $${bulkPrice}<br>`;
            } else {
              const tier = `${bulkRates[i].minQuantity} or more`;
              bulkRateInfo += `Buy ${tier} and get a unit price of $${bulkPrice}`;
            }
          }
          bulkRateInfoDisplay.innerHTML = bulkRateInfo;

          if (isCouponApplied) {
            if (!discountMsgEl) {
              bulkRateInfoDisplay.insertAdjacentHTML(
                'afterend',
                '<p class="modal-discounts-msg small text-muted fst-italic mt-2 mb-0">Other discounts may be applied in the cart</p>'
              );
            }
          } else {
            discountMsgEl && discountMsgEl.remove();
          }
        }
        // if product doesn't have bulk tier pricing, either because it normally doesn't or because of the coupon or group ID
        else {
          bulkRateInfoDisplay.innerHTML = `<em>Contact us to speak to a sales representative for bulk pricing.</em>`;
        }

        // comment and uncomment one of the boostrap modal triggers based on version of bootstrap
        // for bootstrap v4+
        // bulkModal.modal('show');
        // for boostrap v5+
        bulkModal.show();
      }
    };

    for (let trigger of modalTriggers) {
      trigger.addEventListener('click', showModal);
      trigger.addEventListener('keydown', (e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          showModal(e);
        }
      });
    }
  },

  initCartTotalAndBtn: (totalAmount) => {
    const bulkTable = bulkOrder.bulkTable;
    const totalDisplay = bulkTable.querySelector('.total-display');
    const viewCartBtn = bulkTable.querySelector('.add-to-cart-bulk');

    if (totalDisplay) {
      const formatCurrency = (totalAmount) => {
        const amount = Number(totalAmount);
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
      };
      totalDisplay.innerHTML = formatCurrency(totalAmount);
    }

    if (totalAmount > 0) {
      const testPrice = cart.readCookie('_up4tp') ? `&tp=${cart.readCookie('_up4tp')}` : '';
      const couponCode = cart.readCookie('_up4cc') ? `&coupon=${cart.readCookie('_up4cc')}` : '';
      const groupId = cart.readCookie('_up4gid') ? `&groupId=${cart.readCookie('_up4gid')}` : '';

      // enable view cart button
      viewCartBtn.classList.remove('disabled');
      viewCartBtn.href = `${cart.cartBaseUrl}/cart/?cartId=${cart.getCartId()}${testPrice}${couponCode}${groupId}`;
      viewCartBtn.tabIndex = null;
      viewCartBtn.ariaHidden = false;

      // if there is a coupon in the cart display the discount message
      const discountMsgEl = bulkTable.querySelector('.discounts-msg');
      const localCart = cart.getLocalCart();
      const isCouponApplied = localCart?.cartProducts?.some((product) => product?.discountSource === 'Coupon');
      if (isCouponApplied) {
        if (!discountMsgEl) {
          viewCartBtn.insertAdjacentHTML(
            'afterend',
            `<p class="discounts-msg small text-muted fst-italic mt-2 mb-0">Other discounts may be applied in the cart</p>`
          );
        }
      } else {
        discountMsgEl && discountMsgEl.remove();
      }
    } else {
      // disable view cart button
      viewCartBtn.classList.add('disabled');
      viewCartBtn.href = '#';
      viewCartBtn.tabIndex = -1;
      viewCartBtn.ariaHidden = true;
    }

    // using JS to send user to the cart so that we can reinitialize the bulk page if the user clicks the browser's back button
    viewCartBtn.addEventListener(
      'click',
      (event) => {
        event.preventDefault();

        sessionStorage.setItem('viewCartFromBulk', true);

        window.location.href = viewCartBtn.href;
      },
      true
    );
  },
};

// inititalize the bulk order page
bulkOrder.init(true);

const reInitBulkOrder = () => {
  if (sessionStorage.getItem('viewCartFromBulk') === 'true') {
    sessionStorage.removeItem('viewCartFromBulk');
    bulkOrder.init(true);
  }
};

// this makes sure that the cart data is refetched when the user hits the back button from in the browser
// removing eventListener before adding one to prevent duplicates
window.removeEventListener('pageshow', () => reInitBulkOrder());
window.addEventListener('pageshow', () => reInitBulkOrder());

(() => {
  const activeClass = 'is-open';
  const hiddenClass = 'invisible';

  const toggleDescription = (eventElement) => {
    eventElement.setAttribute('aria-expanded', eventElement.getAttribute('aria-expanded') === 'false' ? 'true' : 'false');
    eventElement.setAttribute('aria-pressed', eventElement.getAttribute('aria-pressed') === 'false' ? 'true' : 'false');

    const descriptionContainer = eventElement.parentNode;
    const productId = descriptionContainer.dataset.pid;
    const descriptionContent = descriptionContainer.querySelector('.description-content');
    // Toggle the "is-open" class for the closest "description-content" element
    if (descriptionContainer && !descriptionContainer.classList.contains(activeClass)) {
      descriptionContainer.classList.add(activeClass);
      descriptionContent.classList.remove(hiddenClass);
      // adding productId to array of opened description
      bulkOrder.expandedDescription.push(productId);
    } else {
      descriptionContainer.classList.remove(activeClass);
      descriptionContent.classList.add(hiddenClass);
      // removing productId to array of opened description
      bulkOrder.expandedDescription.splice(bulkOrder.expandedDescription.indexOf(productId), 1);
    }
  };

  document.addEventListener('click', (e) => {
    const eventElement = e.target.closest('.description-toggle');
    // Check if the clicked element has the class "description-toggle"
    if (eventElement) {
      // call toggle function
      toggleDescription(eventElement);
    }
  });
  document.addEventListener('keydown', (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      const eventElement = e.target.closest('.description-toggle');
      if (eventElement) {
        if (e.key === ' ') {
          e.preventDefault();
        }
        toggleDescription(eventElement);
      }
    }
  });
})();
